import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";

const Regions = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image);

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <p>{data.mdx.frontmatter.date}</p>
      <GatsbyImage image={image} alt={data.mdx.frontmatter.hero_image_alt} />
      <p>Secretary:</p>
      <p>
        <a style={{ color: "#4a71b6", textDecoration: "none" }} target="_new" href={data.mdx.frontmatter.secretary_mailto}>
          {data.mdx.frontmatter.secretary}
        </a>
      </p>
      <p>Reception Officer:</p>
      <p>
        <a style={{ color: "#4a71b6", textDecoration: "none" }} target="_new" href={data.mdx.frontmatter.reception_officer_mailto}>
          {data.mdx.frontmatter.reception_officer}
        </a>
      </p>
      <p>Tourism Website:</p>
      <p>
        <a style={{ color: "#4a71b6", textDecoration: "none" }} target="_new" rel="noreferrer" href={data.mdx.frontmatter.tourism_site}>
          {data.mdx.frontmatter.tourism_site_label}
        </a>
      </p>
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        secretary
        secretary_mailto
        reception_officer
        reception_officer_mailto
        tourism_site
        tourism_site_label
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`;

export default Regions;
